import {
  escapeRegExp, isInteger,
} from 'lodash-es';
import i18next from '@/lib/i18next';

const NUMBER_EXP = '(?<number>[0-9]+)';
const NUMBER_PARENS_EXP = `\\(${NUMBER_EXP}\\)`;

export function getCopyFormatExp() {
  /*
    Gets localized copy format with the %(number)s and %(title)s
    placeholders still present.
  */
  const copyTitleFormat = i18next.t('Copy %(number)s %(title)s', {
    number: '%(number)s',
    title: '%(title)s',
  });
  const copyFormat = copyTitleFormat.replace('%(title)s', '').trim().replace(/ +/, ' ');
  const copyFormatExp = new RegExp(
    escapeRegExp(copyFormat).replace('%\\(number\\)s', NUMBER_PARENS_EXP),
  );
  return copyFormatExp;
}

export function getNextTitleFormatExp(nextTitleFormat) {
  const nextFormatExp = new RegExp(escapeRegExp(nextTitleFormat)
    .replace('%\\(number\\)s', NUMBER_EXP));
  return nextFormatExp;
}

export function removeTitleCopy(title) {
  return (title || '').replace(getCopyFormatExp(), '').trim();
}

export function getWindowTitleFormat() {
  // Return localized format with number placeholder present.
  return i18next.t('Window %(number)s', {
    number: '%(number)s',
  });
}

/**
 * Gets the next incremented copy of a title name.
 * @param {String} title The title to get the next copy of.
 * @param {Array} existingTitles List of existing titles to compare against.
 * @returns The incremented copy of the given title name. e.g.
 * when:
 * title === 'Window 1'
 * and existingTitles = []
 * returns 'Copy (1) Window 1'
 * when title === 'Window 1'
 * and existingTitles = ['Window 1, 'Copy (1) Window 1']
 * returns 'Copy (2) Window 1'
 * when title === 'Copy (1) Window 1'
 * and existingTitles = ['Window 1', 'Copy (1) Window 1', 'Copy (2) Window 1']
 * returns 'Copy (3) Window 1'
 */
export function getCopyTitle(title, existingTitles) {
  const copyFormatExp = getCopyFormatExp();
  const titleWithoutCopy = removeTitleCopy(title);
  const matchingTitles = existingTitles
    .filter((t) => removeTitleCopy(t) === titleWithoutCopy);
  if (!matchingTitles.length) {
    return title;
  }
  const copyNumbers = matchingTitles
    .map((t) => {
      const match = t?.match(copyFormatExp);
      return Number(match?.groups?.number);
    })
    .filter((copyNumber) => isInteger(copyNumber));
  const nextCopyNumber = Math.max(0, ...copyNumbers) + 1;
  return i18next.t('Copy %(number)s %(title)s', {
    number: `(${nextCopyNumber})`,
    title: titleWithoutCopy,
  });
}

/**
 * Gets the next incremented title name matching the specified format.
 * @param {Array} existingTitles List of existing titles to compare against.
 * @param {String} nextTitleFormat Expects a localized string with the %(number)s placeholder still present.
 * e.g. i18next.t('Window %(number)s', { number: '%(number)s'})
 * @returns The next incremented title name. e.g.
 * when:
 * existingTitles = []
 * returns 'Window 1'
 * and existingTitles = ['Window 1']
 * returns 'Window 2'
 * and existingTitles = ['Window 1', 'Window 3']
 * returns 'Window 4'
 */
export function getNextTitle(existingTitles, nextTitleFormat) {
  const nextFormatExp = getNextTitleFormatExp(nextTitleFormat);
  const titleNumbers = existingTitles
    .map((t) => {
      const match = t?.match(nextFormatExp);
      return Number(match?.groups?.number);
    })
    .filter((titleNumber) => isInteger(titleNumber));
  const nextTitleNumber = Math.max(0, ...titleNumbers) + 1;
  return nextTitleFormat.replace('%(number)s', `${nextTitleNumber}`);
}

export default {
  getCopyFormatExp,
  getCopyTitle,
  getNextTitle,
  getNextTitleFormatExp,
  getWindowTitleFormat,
  removeTitleCopy,
};
