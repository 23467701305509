export function loadZendesk(siteCountryCode) {
  // Add Zendesk script to the page. This needs to be added dynamically since it's supposed to be
  // included AFTER Pendo loads
  if (siteCountryCode) {
    const zendeskScript = document.createElement('script');
    const zendeskKey = siteCountryCode === 'GB'
      ? '1f40190f-4a3b-4308-b222-fdb0a882f310'
      : '69139f7f-d202-4995-a641-71cc8f171dae';

    zendeskScript.id = 'ze-snippet';
    zendeskScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    document.body.appendChild(zendeskScript);
  }
}

export function loadZoomAgent() {
  const zoomScript = document.createElement('script');
  zoomScript.setAttribute('data-apikey', 'z8zeU64oTYauNAr7l3bCbQ');
  zoomScript.setAttribute('data-env', 'us01');
  zoomScript.setAttribute('defer', '');
  zoomScript.src = 'https://us01ccistatic.zoom.us/us01cci/web-sdk/zcc-sdk.js';

  document.body.appendChild(zoomScript);
}
