import {
  filter,
  forEach,
  isArray,
  isPlainObject,
  reject,
  some,
} from 'lodash-es';
import {
  docFromString,
  findDocNodes,
  toDocString,
} from '@discoveryedu/titan-text-editor';
import * as moduleDefaults from '@/lib/constants/moduleDefaults';
import { wordTrackingDefault } from '@/lib/constants';

/**
 * Removes word tracking from the specified module.
 * @param {Object} modToUpdate Module to remove word tracking from.
 */
export function removeWordTracking(modToUpdate) {
  const mod = modToUpdate;
  mod.options.word_tracking = undefined;
  if (mod.type === 'text' && mod.options.content) {
    const content = JSON.parse(mod.options.content);

    const replaceClassesExp = wordTrackingDefault()
      .map((item) => `${item.key}-[0-9-]+`)
      .join('|');

    const replaceClasses = (classes) => (classes || '')
      .replace(new RegExp(replaceClassesExp, 'g'), '')
      .replace(/\s+/g, ' ');

    /*
      Removes all word tracking specific class attributes
      from document nodes.
    */
    const removeWordTrackingClasses = (docNode) => {
      const node = docNode;
      if (isPlainObject(node)) {
        const attrClasses = node?.attrs?.class;
        if (attrClasses) {
          node.attrs.class = replaceClasses(attrClasses);
        }
        if (isArray(node.content)) {
          node.content.forEach((item) => removeWordTrackingClasses(item));
        }
      }
      return node;
    };

    mod.options.content = JSON.stringify(removeWordTrackingClasses(content));
  }
}

/**
 * Removes window links for the specified module.
 * @param {Object} module Module to remove links from.
 * @param {String} modalCanvasId Only remove links to this modal id if provided.
 */
export function removeWindowLinks({
  module,
  modalCanvasId,
  disableTextLinks = false,
}) {
  const mod = module;
  let hasWindowLinks = mod.options.action === 'window'
    && (modalCanvasId ? mod.options.target_page === modalCanvasId : true);
  // Remove window links from advanced buttons and assets.
  if (hasWindowLinks) {
    if (mod.type === 'advanced_button') {
      const defaults = moduleDefaults.advancedButtonModuleDefault(true);
      mod.options.action = defaults.options.action;
      mod.options.target = defaults.options.target;
      mod.options.target_page = defaults.options.target_page;
    } else {
      mod.options.action = undefined;
      mod.options.target_page = undefined;
    }
  }
  // Remove window links from text modules.
  if (mod.type === 'text') {
    const doc = docFromString(mod.options.content);
    if (doc) {
      const findWindowLinks = (m) => m.type === 'button'
          && m.attrs?.['data-action'] === 'window'
          && (modalCanvasId ? m.attrs?.['data-target-page'] === modalCanvasId : true);
      const nodes = findDocNodes(doc, (n) => some(n?.marks, findWindowLinks));
      if (nodes.length) {
        hasWindowLinks = true;
        forEach(nodes, (node) => {
          const updateNode = node;
          if (disableTextLinks) {
            /*
              Only make window links inactive. We need to keep the window
              link mark in the document as a reference so we can update it
              from the UI.
            */
            filter(node.marks, findWindowLinks).forEach((mark) => {
              const updateMark = mark;
              updateMark.attrs['data-inactive'] = 'true';
              if (updateMark.attrs['data-target-page']) {
                delete updateMark.attrs['data-target-page'];
              }
            });
          } else {
            // Remove window links completely.
            updateNode.marks = reject(node.marks, findWindowLinks);
          }
        });
        mod.options.content = toDocString(doc);
      }
    }
  }
  return {
    hasWindowLinks,
    module: mod,
  };
}

export default {
  removeWindowLinks,
  removeWordTracking,
};
