import { mapActions, mapState } from 'pinia';
import { isGuid } from '@/lib/utils';
import addModule from '@/mixins/addModule';
import { validatePasteAssetId } from '@/lib/utils/copyPaste';
import {
  useEditorStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

export default {
  mixins: [addModule],
  computed: {
    ...mapState(useEditorStore, [
      'activeCanvasName',
      'currentModalCanvasId',
      'editorViewModePreview',
    ]),
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.PASTE,
    ]),
    async onPaste(e) {
      // If we're in preview mode, exit
      if (this.editorViewModePreview) return;

      // If we're editing text, exit. Sometimes if we're editing an empty
      // text module, the target isn't really in the DOM inside that module,
      // but it'll return an empty parent node.
      if (e.target.closest('input[type=text], textarea, .text-editor__content--focused')
        || !e.target.parentNode) {
        return;
      }

      e.preventDefault();

      const canvasName = this.currentModalCanvasId
        ? 'modalModules'
        : (this.activeCanvasName || 'modules');

      // If there are files on the clipboard, upload those rather than reading
      // clipboard text
      if (e.clipboardData.files.length) {
        this[types.PASTE]({
          canvasName,
          copyData: {
            files: e.clipboardData.files,
          },
          onFilesUploaded: this.addModulesFromMyContent,
        });
        return;
      }

      const pastedText = e.clipboardData.getData('text/plain');
      if (pastedText) {
        // First check if we've pasted a guid
        if (isGuid(pastedText)) {
          this[types.PASTE]({
            canvasName,
            copyData: {
              assetId: pastedText,
            },
            getModulePosition: this.getVisibleCanvasCenter,
          });
          return;
        }

        // Check for invalid guid with special characters and incorrect guid length
        if (!validatePasteAssetId(pastedText)) {
          return;
        }

        // Otherwise attempt to parse copyData JSON and paste
        try {
          this[types.PASTE]({
            canvasName,
            copyData: JSON.parse(pastedText),
          });
        } catch (error) {
          // If we get an exception here it means that the user tried to paste
          // something that was not valid JSON; ignore it.
        }
      } else if (!this.isModuleClipboardEmpty) {
        // If there's nothing on the device clipboard, try pasting
        // from the app store module clipboard instead.
        this[types.PASTE]({
          canvasName,
        });
      }
    },
  },
};
