import { defineStore } from 'pinia';
import { get } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import * as types from '@/lib/constants/store';

const useModalStore = defineStore('studio-modal', {
  state: () => ({
    modals: [],
    myContentModalOptions: {
      config: {
        hideDistrictTab: false,
        isActive: false,
        isStudent: true,
        selectMultiple: false,
      },
      onChooseAssets: null,
    },
  }),
  getters: {
    modalIsOpen: (state) => (
      !!state.modals.length || get(state, 'myContentModalOptions.config.isActive')
    ),
  },
  actions: {
    [types.OPEN_MODAL](payload) {
      this.modals.push({
        ...payload,
        id: payload.id || uuid(),
      });
    },

    [types.CLOSE_MODAL](payload) {
      const idx = this.modals.findIndex((modal) => modal.id === payload.id);
      if (idx !== -1) {
        this.modals.splice(idx, 1);
      }
    },

    [types.UPDATE_MODAL](payload) {
      const idx = this.modals.findIndex((modal) => modal.id === payload.id);
      if (idx !== -1) {
        this.modals[idx] = payload;
      }
    },

    [types.TOGGLE_MODAL](payload) {
      const modal = {
        ...payload,
        id: payload.id || uuid(),
      };

      const idx = this.modals.findIndex((m) => m.id === modal.id);
      if (idx !== -1) {
        this.modals.splice(idx, 1);
      } else {
        this.modals.push(modal);
      }
    },

    [types.CLEAR_MODALS]() {
      this.modals = [];
    },

    [types.SET_MY_CONTENT_MODAL_OPTIONS](payload) {
      this.myContentModalOptions = payload;
    },
  },
});

export default useModalStore;
