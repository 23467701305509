import {
  camelCase,
  cloneDeep,
  forEach,
  last,
  map,
  omit,
} from 'lodash-es';
import { v4 as uuid } from 'uuid';
import {
  getCopyTitle,
} from '@/lib/utils/titleFormat';
import {
  docFromString,
  findDocNodes,
  toDocString,
} from '@discoveryedu/titan-text-editor';
import {
  EDITOR_CANVAS_WIDTH,
  EDITOR_CANVAS_HEIGHT,
  MODAL_CANVAS_WIDTH,
  MODAL_CANVAS_HEIGHT,
  MODAL_CANVAS_PLACE_MODULE_BLEED,
} from '@/lib/constants';
import placeModule from '@/lib/utils/placeModule';
import {
  bottomYPosition,
  createTopZPosition,
} from '@/lib/utils/grid';
import {
  newPageTitle,
} from '@/lib/utils/store';
import {
  removeWindowLinks,
  removeWordTracking,
} from '@/lib/utils/module';
import * as moduleDefaults from '@/lib/constants/moduleDefaults';
import i18next from '@/lib/i18next';
import { isGuid } from '@/lib/utils';
import chain from '@/lib/utils/chain';
// eslint-disable-next-line import/no-cycle
import {
  useAssetStore,
  useEditorStore,
  useToastStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

export function copyModules({ modules, modalCopyMap }) {
  const moduleCopies = map(modules, (mod) => {
    const moduleCopy = {
      ...cloneDeep(mod),
      id: uuid(),
      // Remove 'id' from options if it's there.
      options: omit(cloneDeep(mod.options), ['id']),
    };

    if (modalCopyMap) {
      const targetPage = moduleCopy.options?.target_page;
      const action = moduleCopy.options?.action;

      // Use modalCopyMap to update links to modals
      if (action === 'window' && targetPage) {
        const newModalId = modalCopyMap[targetPage]?.id;
        // Only update if there is a mapping for target_page
        if (newModalId) {
          moduleCopy.options.target_page = newModalId;
        }
      }

      // Use modalCopyMap to update links to modals within a text module.
      if (mod.type === 'text') {
        const doc = docFromString(moduleCopy.options?.content);
        if (doc) {
          // Update links in the ProseMirror doc.
          const buttons = findDocNodes(doc, (node) => node['data-action'] === 'window');
          buttons.forEach((button) => {
            const updateButton = button;
            const buttonTargetPage = button['data-target-page'];
            const newModalId = modalCopyMap[buttonTargetPage]?.id;
            // Only update if there is a mapping for target_page
            if (newModalId) {
              updateButton['data-target-page'] = newModalId;
            }
          });
          moduleCopy.options.content = toDocString(doc);
        }
      }
    }

    return moduleCopy;
  });

  return moduleCopies;
}

export function copyPages({
  pages,
  modalCopyMap,
}) {
  const editorStore = useEditorStore();
  const {
    currentPageIndex,
    pagesSorted,
    pagesById,
    currentPage,
  } = editorStore;
  const nonExistingPageCount = pages.filter((p) => !pagesById[p.id])?.length;
  const currentPageSortIndex = currentPage.sort_index;
  const currentPageIsLastPage = last(pagesSorted)?.id === currentPage?.id;
  const lastPageSortIndex = last(pagesSorted)?.sort_index || 1;
  const maxPageIndex = pagesSorted.length - 1;
  let fromCurrentPageIncrement = 0;
  return map(pages, (page) => {
    const titleExists = pagesSorted.find((p) => p.title === page.title);
    /*
      Calculate a new sort_index for each new page
    */
    const pageIndex = pagesSorted.findIndex((p) => p.id === page.id);
    let pageSortIndex;
    if (pageIndex === maxPageIndex) {
      // If we're copying the last page, add 1 to the sort_index
      pageSortIndex = lastPageSortIndex + 1;
    } else if (pageIndex === -1) {
      /*
        If the pages we're copying don't exist, then place copied pages between
        the current page and the next page after that. This happens when pasting
        from one draft to another.
      */
      if (currentPageIsLastPage) {
        fromCurrentPageIncrement += 1;
        pageSortIndex = currentPageSortIndex + fromCurrentPageIncrement;
      } else {
        const nextSortIndex = pagesSorted[currentPageIndex + 1].sort_index;
        const sortIndexDiff = nextSortIndex - currentPageSortIndex;
        fromCurrentPageIncrement += sortIndexDiff / (nonExistingPageCount + 1);
        pageSortIndex = currentPageSortIndex + fromCurrentPageIncrement;
      }
    } else {
      // Place page between the page we're copying and the next page.
      const nextSortIndex = pagesSorted[pageIndex + 1].sort_index;
      const existingPageSortIndex = pagesSorted[pageIndex].sort_index;
      pageSortIndex = (existingPageSortIndex + nextSortIndex) / 2;
    }
    const pageCopy = {
      ...cloneDeep(page),
      id: uuid(),
      title: titleExists ? newPageTitle(page.title) : page.title,
      modules: copyModules({
        modules: page.modules,
        modalCopyMap,
      }),
      notes: copyModules({
        modules: page.notes,
        modalCopyMap,
      }),
      sort_index: pageSortIndex,
    };
    return pageCopy;
  });
}

export function copyModals({
  modals,
  forceCopy = false,
}) {
  const editorStore = useEditorStore();
  const modalCopyMap = {};
  const modalTitles = editorStore.modalCanvases.map((c) => c.title);
  forEach(modals, (modal) => {
    /*
      Determine if the modal we're copying has already been pasted or already exists.
      If the source_id matches the id of the modal we're pasting, that means
      this modal has already been pasted so we will not paste it again.
      The source_id on a pasted modal gets removed as soon as the user makes a change
      to the modal.
    */
    const existingModal = editorStore.modalCanvases.find(
      (c) => c.id === modal.id || c.options?.source_id === modal.id,
    );
    const existingModalSourceId = existingModal?.options?.source_id;
    // Only copy the modal if it doesn't already exist or we are forcing a copy.
    const shouldCopy = forceCopy || !existingModal;
    if (shouldCopy) {
      const title = getCopyTitle(modal.title, modalTitles);
      const modalCopy = {
        ...cloneDeep(modal),
        id: uuid(),
        title,
        modules: copyModules({ modules: modal.modules }),
        options: {
          ...cloneDeep(modal.options),
          /*
            When we forceCopy, we copy the modal even though it already
            exists. In this case, do not set source_id. This is used to duplicate
            a modal in the UI.
          */
          source_id: forceCopy ? undefined : modal.id,
        },
      };
      modalTitles.push(title);
      modalCopyMap[modal.id] = modalCopy;
    } else if (existingModalSourceId === modal.id) {
      /*
        If we found an existing modal that is a copy of the one we're pasting,
        then add a mapping to the modalCopyMap that can be used later to update
        modal button links in modules being pasted.
      */
      modalCopyMap[modal.id] = existingModal;
    }
  });
  return {
    modalCopyMap,
    modals: Object.values(modalCopyMap),
  };
}

export function getModalsForModule(module) {
  const editorStore = useEditorStore();
  const modalCopies = [];

  const addModalById = (modalId) => {
    const findModalById = (m) => m.id === modalId;
    if (modalCopies.find(findModalById)) return;
    const modal = editorStore.draft.modals.find(findModalById);
    if (modal) {
      modalCopies.push(cloneDeep(modal));
    }
  };

  const targetPage = module.options?.target_page;
  const action = module.options?.action;

  // Add modals attached to this module.
  if (action === 'window' && targetPage) {
    addModalById(targetPage);
  }

  // Add modals used by links within the text module content.
  if (module.type === 'text') {
    const doc = docFromString(module.options?.content);
    if (doc) {
      const buttons = findDocNodes(doc, (node) => node['data-action'] === 'window');
      buttons.forEach((button) => {
        addModalById(button['data-target-page']);
      });
    }
  }

  return modalCopies;
}

export function getModalsForPage(page) {
  const pageModules = [...page.modules, ...(page.notes || [])];
  return chain(pageModules)
    .map((m) => getModalsForModule(m))
    .flatten()
    .uniqBy('id')
    .value();
}

export function getModalsForPages(pages) {
  return chain(pages)
    .map((m) => getModalsForPage(m))
    .flatten()
    .uniqBy('id')
    .value();
}

export function getPastePosition({
  module,
  canvasName,
  sourceCanvasName,
  getModulePosition,
}) {
  const editorStore = useEditorStore();
  const modulesOrderedByZ = canvasName === 'modalModules'
    ? editorStore.getModulesByModalIdOrderedByZPosition(editorStore.currentModalCanvasId)
    : editorStore.modulesOrderedByZPosition;
  /*
    If a 'getModulePosition()' function was passed in, use it to resolve the
    starting paste position for the module.
  */
  const defaultPosition = getModulePosition ? getModulePosition(module) : null;
  const canvasInfo = canvasName === 'modalModules'
    ? {
      width: MODAL_CANVAS_WIDTH,
      height: MODAL_CANVAS_HEIGHT,
      bleed: MODAL_CANVAS_PLACE_MODULE_BLEED,
      defaultPosition,
    }
    : {
      width: EDITOR_CANVAS_WIDTH,
      height: editorStore.draftType === 'board'
        ? editorStore.currentPage?.options?.height || EDITOR_CANVAS_HEIGHT
        : EDITOR_CANVAS_HEIGHT,
      defaultPosition,
    };

  /*
    When copy and pasting from one canvas to another, try
    to preserve the module's position. If the module's position
    is inside the bounds of the target canvas, then paste the
    module to its original position.
  */
  const tryPreserveModPosition = sourceCanvasName !== 'notes'
    && canvasName !== 'notes'
    && !canvasInfo.defaultPosition;
  if (tryPreserveModPosition) {
    const position = {
      x: module.layout.x_position,
      y: module.layout.y_position,
    };
    let canvasWidth;
    let canvasHeight;
    if (canvasName === 'modalModules') {
      canvasWidth = MODAL_CANVAS_WIDTH;
      canvasHeight = MODAL_CANVAS_HEIGHT;
    } else {
      canvasWidth = EDITOR_CANVAS_WIDTH;
      canvasHeight = editorStore.draftType === 'board'
        ? editorStore.currentPage?.options?.height || EDITOR_CANVAS_HEIGHT
        : EDITOR_CANVAS_HEIGHT;
    }
    const right = position.x + module.layout.width;
    const bottom = position.y + module.layout.height;
    const leftInside = position.x >= 0 && position.x <= canvasWidth;
    const rightInside = right >= 0 && right <= canvasWidth;
    const topInside = position.y >= 0 && position.y <= canvasHeight;
    const bottomInside = bottom >= 0 && bottom <= canvasHeight;
    const moduleInsideCanvas = (topInside && leftInside)
      || (topInside && rightInside)
      || (bottomInside && rightInside)
      || (bottomInside && leftInside);

    if (moduleInsideCanvas) {
      /*
        If the module's current position is inside the canvas it's being
        pasted into, then use the that position as starting placement position.
      */
      canvasInfo.defaultPosition = position;
    }
  }

  // Get the final postion to paste the module to.
  const coordinates = placeModule(
    modulesOrderedByZ,
    module,
    canvasInfo,
  );

  return {
    x_position: coordinates.x,
    y_position: coordinates.y,
    z_position: createTopZPosition(modulesOrderedByZ),
  };
}

export function isPasteChangingLayout({ canvasName, sourceCanvasName }) {
  /*
    Returns true if the module is being pasted to a canvas that has a
    different coordinate system than the source.
  */
  return canvasName
    && sourceCanvasName
    && canvasName !== sourceCanvasName
    && [canvasName, sourceCanvasName].includes('notes');
}

export function getDefaultLayout({ module, canvasName }) {
  const layout = cloneDeep(module.layout);
  const assetStore = useAssetStore();
  const typeKey = `${camelCase(module?.type)}ModuleDefault`;
  const targetHasGridDisabled = canvasName !== 'notes';
  const assetId = module?.options?.asset_id;
  const asset = assetStore.assets[assetId];

  // Gets the default layout for the specified module.
  if (moduleDefaults[typeKey]) {
    const defaultModule = moduleDefaults[typeKey](targetHasGridDisabled, asset);
    return {
      ...layout,
      ...defaultModule.layout,
    };
  }

  return layout;
}

export function canAddToNotes(module) {
  return ![
    'prompt',
    'single_selection',
    'multiple_selection',
    'block',
  ].includes(module.type);
}

export function getPasteModuleOptions({ module, canvasName }) {
  /*
    Gets adjusted options for the module based on the canvas
    it is being pasted into.
  */
  const mod = cloneDeep(module);
  if (canvasName === 'notes') {
    // Video options not allowed in notes modules.
    if (mod.options?.auto_advance) {
      mod.options.auto_advance = false;
    }
    if (mod.options?.auto_play) {
      mod.options.auto_play = false;
    }
    if (mod.options?.loop_video) {
      mod.options.loop_video = false;
    }
    // Word tracking not allowed in the notes tab.
    if (mod.options?.word_tracking) {
      removeWordTracking(mod);
    }
  } else if (canvasName === 'modalModules') {
    // Auto advance video option not allowed on the modal canvas.
    if (mod.options?.auto_advance) {
      mod.options.auto_advance = false;
    }
    // Remove window links from advanced buttons and assets.
    removeWindowLinks({ module: mod });
  }
  return mod.options;
}

export function getPasteModuleLayout({
  module,
  canvasName,
  sourceCanvasName,
  sourceModulePageId,
  sourceDraftId,
  getModulePosition,
}) {
  let layout = cloneDeep(module.layout);

  /*
    Get the layout object for the module being pasted based
    on the canvas it's being pasted into.
  */
  if (canvasName === 'notes') {
    const editorStore = useEditorStore();
    // if we're jumping across canvases, we need to put the modules on the bottom
    const addToBottom = (canvasName === 'notes' && sourceCanvasName !== 'notes')
      || editorStore.currentPage.id !== sourceModulePageId
      || editorStore.draft.id !== sourceDraftId;
    // Rotation not allowed in notes
    layout.rotate_degree = undefined;
    if (addToBottom) {
      // place module at the bottom of the notes
      layout.y_position = bottomYPosition(editorStore.currentPage.notes);
    } else {
      layout.y_position += module.layout.height;
    }
  } else {
    layout = {
      ...layout,
      ...getPastePosition({
        module,
        canvasName,
        sourceCanvasName,
        getModulePosition,
      }),
    };
  }

  return layout;
}

export async function getPasteModuleFromAssetId({ pasteData, canvasName }) {
  const assetStore = useAssetStore();
  const toastStore = useToastStore();
  // Get the asset for the asset id being pasted.
  const result = await assetStore[types.GET_ASSET]({
    assetId: pasteData.assetId,
    fromCache: true,
  });

  if (result.asset) {
    const { asset } = result;
    const gridIsDisabled = canvasName !== 'notes';
    // Create a new asset module for the pasted asset id.
    const module = moduleDefaults.assetModuleDefault(gridIsDisabled, asset);
    module.options.asset_id = asset.id;
    return module;
  }

  const errorMsg = i18next.t('Paste failed: invalid asset');
  toastStore[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMsg });
  return null;
}

export function validatePasteData({ pasteData, moduleToReplace, canvasName }) {
  const toastStore = useToastStore();
  const editorStore = useEditorStore();
  if (!pasteData || !canvasName) {
    return false;
  }
  if (moduleToReplace && !pasteData.module) {
    return false;
  }
  if (pasteData.assetId && !isGuid(pasteData.assetId)) {
    const errorMsg = i18next.t('Asset id must be a GUID');
    toastStore[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMsg });
    return false;
  }
  if (pasteData.module && canvasName === 'notes' && !canAddToNotes(pasteData.module)) {
    const errorMsg = i18next.t('This module cannot be added to Teacher Notes');
    toastStore[types.SET_STUDIO_TOAST]({ type: 'error', message: errorMsg });
    return false;
  }
  if (pasteData.pages && pasteData.draftType === 'board' && editorStore.draftType !== 'board') {
    toastStore[types.SET_STUDIO_TOAST]({
      type: 'error',
      message: i18next.t('A board page cannot be pasted into a slideshow.'),
      position: 'top',
    });
    return false;
  }
  if (pasteData.pages && pasteData.draftType !== 'board' && editorStore.draftType === 'board') {
    toastStore[types.SET_STUDIO_TOAST]({
      type: 'error',
      message: i18next.t('A slide cannot be pasted into a board.'),
      position: 'top',
    });
    return false;
  }
  const hasFullSlideAssets = !!pasteData.pages?.some((page) => page.options?.asset_id);
  if (editorStore.draftType !== 'lesson' && hasFullSlideAssets) {
    toastStore[types.SET_STUDIO_TOAST]({
      type: 'error',
      message: i18next.t('Full-slide assets can only be used in lessons.'),
      position: 'top',
    });
    return false;
  }
  return true;
}

export function validatePasteAssetId(assetId) {
  const toastStore = useToastStore();
  const chunksSplitHyphen = assetId.split('-');
  const hasFiveChunksHyphen = chunksSplitHyphen.length === 5;
  const specialChars = /[!@#$%^&*()_+=[\]{};':"\\|,.<>/?]/;
  const hasFiveChunksSpecialChars = assetId.split(specialChars).length === 5;
  /*
    Check a few special cases:
    1. Pattern is 5 chunks, split with a '-', but the last chunk has more
    or less than 12 characters.
    2. Pattern is separated with special characters, but is the correct length
    of 36 characters.
  */
  if (
    (hasFiveChunksHyphen && last(chunksSplitHyphen).length !== 12)
    || (hasFiveChunksSpecialChars && assetId.length === 36)
  ) {
    toastStore[types.SET_STUDIO_TOAST]({
      type: 'error',
      message: i18next.t('Paste failed: Invalid asset guid'),
      position: 'top',
    });
    return false;
  }
  return true;
}

export default {
  canAddToNotes,
  copyModals,
  copyModules,
  copyPages,
  getDefaultLayout,
  getModalsForModule,
  getModalsForPage,
  getModalsForPages,
  getPasteModuleFromAssetId,
  getPasteModuleLayout,
  getPasteModuleOptions,
  getPastePosition,
  isPasteChangingLayout,
  validatePasteAssetId,
  validatePasteData,
};
