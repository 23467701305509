import { defineStore } from 'pinia';
import { get, keyBy } from 'lodash-es';
import {
  useAppStore,
  useTrackingStore,
  useEditorStore,
} from '@/stores';
import { getLocalData } from '@/lib/localData';
import {
  EDITORIAL_ROLES,
  ROLE_GLOBAL_ADMIN,
  ROLE_EDITOR,
  ROLES_WITH_STUDIO_ACCESS,
  ROLE_STUDENT,
  PRODUCT_SETTING_ALLOW_STUDIO,
  PRODUCT_SETTING_STUDIO_TEMPLATES_ENABLED,
  PRODUCT_SETTING_CODES,
} from '@/lib/constants';
import chain from '@/lib/utils/chain';
import * as appConstants from '@/lib/constants/app';
import * as types from '@/lib/constants/store';

const useUserStore = defineStore('studio-user', {
  state: () => ({
    user: null,
    productSettings: null,
    mazeEnabled: false,
  }),
  getters: {
    userIsStudent: (state) => state.user
      && state.user.roles.some((role) => role.code === ROLE_STUDENT),
    userDataIsLoaded: (state) => state.user && state.productSettings,
    userCanAccessStudio(state) {
      const hasLocalData = !!getLocalData();
      const hasPermissions = get(state, `productSettings.${PRODUCT_SETTING_ALLOW_STUDIO}.value`)
        || this.userHasAnyRole(ROLES_WITH_STUDIO_ACCESS);

      // 9/29/21 FAC users without access to studio should have access to print preview
      return hasLocalData || (this.userDataIsLoaded && hasPermissions) || (window.location.pathname || '').includes('/print-preview');
    },
    userIsImpersonating(state) {
      return !!get(state, 'user.impersonation.impersonated');
    },
    userHasAnyRole: (state) => (roles) => chain(state)
      .get('user.roles')
      .map('code')
      .some((code) => roles.includes(code))
      .value(),
    userCanAssign() {
      return !this.isDeUser && !this.userIsStudent;
    },
    isDeUser() {
      return this.userHasAnyRole(EDITORIAL_ROLES);
    },
    userHasActivityTemplates: (state) => (
      get(state.productSettings, `${PRODUCT_SETTING_STUDIO_TEMPLATES_ENABLED}.value`)
        && get(state.user, 'has_learning_platform')
    ),
    userSharingTypeIds() {
      const hasAdminEditRoles = this.userHasAnyRole([ROLE_GLOBAL_ADMIN, ROLE_EDITOR]);
      return (hasAdminEditRoles ? ['admin'] : []).concat(['edit', 'view', 'readonly']);
    },
    isRtl(state) {
      const editorStore = useEditorStore();
      if (!editorStore.studioAppIsRunning) {
        // check for direction via css inherited from the parent container
        const viewerElement = document.getElementById('studio-app');
        if (viewerElement && typeof window.getComputedStyle === 'function') {
          const cssStyles = window.getComputedStyle(viewerElement);
          if (cssStyles) {
            const direction = cssStyles.getPropertyValue('direction');

            if (direction && direction === 'rtl') {
              return true;
            }
          }
        }

        // check for direction via attribute
        if (viewerElement && typeof viewerElement.closest === 'function') {
          const rtlParentExists = !!viewerElement.closest('[dir="rtl"]');
          if (rtlParentExists) {
            return true;
          }
        }
      }

      /**
       * TODO: we may bring the following back but need to do more testing on how it impacts
       * the STEM Arabic lessons
       */
      // else, if there is a draft, check to see if there is a language
      // const langCode = get(editorStore, 'draft.language.code');

      // if (langCode && langCode !== 'en') {
      //   // use Intl to try and figure out if this asset lang is rtl
      //   try {
      //     const localeInfo = new Intl.Locale(langCode);
      //     const textDirection = get(localeInfo, 'textInfo.direction', false);
      //     if (textDirection) {
      //       return textDirection === 'rtl';
      //     }
      //   } catch (e) {
      //     // do nothing to roll over to the next check
      //   }

      //   // firefox doesn't have the textInfo prop, so we have to check manually
      //   // for arabic as rtl
      //   return ['ar', 'ara'].includes(langCode);
      // }

      return !get(state, 'user.analytics_data.locale.text_direction_is_ltr', true);
    },
  },
  actions: {
    [types.SET_USER](payload) {
      this.user = payload;
    },
    async [types.GET_USER](payload = {}) {
      const appStore = useAppStore();
      const trackingStore = useTrackingStore();

      let result;
      const { allowPublicAccess } = payload;

      // generate the user path
      const path = '/users/me?return_analytics=true&audiences=studio_write';

      // get the user
      try {
        result = await this.deApi.get(path, {
          skipErrorHandling: allowPublicAccess || false,
        });
      } catch (e) {
        if (allowPublicAccess) {
          // For publicly-accessible boards, we'd expect this to error
          // set anonymous status for tracking
          trackingStore[types.SET_IS_ANONYMOUS](true);
          return;
        }

        // Otherwise we have an error we need to handle elsewhere
        throw e;
      }

      if (result && result.status === 200) {
        const user = result.data.user ? {
          ...result.data.user,
          analytics_data: result.data.analytics_data,
          impersonation: result.data.impersonation,
          token: result.data.token,
        } : null;

        // set the user and locale
        await appStore[types.UPDATE_L10N](get(user, 'analytics_data.locale'));
        this.user = user; // used by the app to know who the user is

        // Add Segment if analytics hasn't already been implemented (hide for thumbnail generator
        // and while impersonating). Segment is only available in the US
        if (!window.DEAnalytics
          && this.domains.apolloDomain
          && !getLocalData()
          && !user.impersonation?.impersonated
        ) {
          const segmentKey = appConstants[`VUE_APP_${this.env}_SEGMENTKEY_${this.region}`];
          const regionRegex = /(.*?)\.discoveryeducation(.com|.co\.uk|.ca)/gi.exec(window.location.hostname);

          // if we have a static url and found a segment key
          if (document && segmentKey && user.account && user.site) {
            // is student?
            const isStudent = !!user.roles.find((item) => item.code === 'STUDENT');

            // determine version by user type
            const version = isStudent ? 3 : 1;

            // username only gets sent for non-students
            const username = version === 1 ? user.username : null;

            const segmentData = {
              key: segmentKey,
              ip: user.analytics_data ? user.analytics_data.request_info.ip : null,
              regionTLD: regionRegex?.[2] || null,
              version,
              accountId: user.account.id,
              accountName: user.account.name,
              id: user.id,
              siteId: user.site.id,
              siteName: user.site.name,
              username,
              isImpersonated: user.impersonation ? user.impersonation.impersonated : false,
              impersonatorId: user.impersonation ? user.impersonation.impersonator : null,
              isStudent,
            };

            // apply to the window
            window.DEAnalyticsInitData = segmentData;

            // Generate a segment url with today's YYYYMMDD date as the cachebust key
            const currentDate = new Date();
            const currentDateYear = currentDate.getFullYear();
            let currentDateMonth = currentDate.getMonth() + 1; // months are 0-indexed
            if (currentDateMonth < 10) currentDateMonth = `0${currentDateMonth}`;
            let currentDateDay = currentDate.getDate();
            if (currentDateDay < 10) currentDateDay = `0${currentDateDay}`;
            const cachebustDate = `${currentDateYear}${currentDateMonth}${currentDateDay}`;
            const segmentUrl = `${this.domains.apolloDomain}/js/enterprise-analytics.min.js?key=${cachebustDate}`;

            // segment must be imported after DEAnalyticsInitData is applied to the window
            const segmentTag = document.createElement('script');
            segmentTag.setAttribute('src', segmentUrl);
            segmentTag.onload = () => {
              if (window.DEAnalytics) {
                window.DEAnalytics.init();
              }
            };

            document.head.appendChild(segmentTag);
          }
        }
      } else {
        throw result;
      }
    },
    async [types.GET_PRODUCT_SETTINGS]({ allowPublicAccess }) {
      const queryParams = PRODUCT_SETTING_CODES.map((code) => `codes=${code}`).join('&');
      const response = await this.deApi.get(
        `/users/product_settings?${queryParams}`,
        {
          skipErrorHandling: allowPublicAccess,
        },
      );

      this.productSettings = keyBy(get(response, 'data.settings'), 'code');
    },
  },
});

export default useUserStore;
